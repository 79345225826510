import DatePicker from "react-datepicker";  
import "react-datepicker/dist/react-datepicker.css";  
import axios from "axios";
import { useEffect, useState } from "react";
import {useNavigate} from 'react-router-dom';
import axiosWithAuth from "../utils/axiosWithAuth";
import txt from '../Device/data.json'; //test data
import DeviceDataChart from "./DeviceDataChart";
import DeviceDataTable from "./DeviceDataTable";
import RawDeviceData from "./RawDeviceData";
import DataDurationIntervalForm from "./DataDurationIntervalForm";
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Dropdown from 'react-bootstrap/Dropdown';
import DeviceAlertPreferences from "./Alerts/DeviceAlertPreferences";
import EditableTag from "../Admin-Shared/EditableTag";


function DeviceData (props) {

    const { loginSuccess, userInfo } = useSelector((state) => state.user)
    
    const [data, setData] = useState([]);
    const [rawData, setRawData] = useState([]);
    const [dropdownData, setDropdownData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {deviceId} = useParams();
    const navigate = useNavigate();
    const [endDate, setEndDate] = useState(new Date());
    const [tag, setTag] = useState([]);

    var userData = {
        "userId": userInfo.guid
    }

    //query for default timeframe
    var startUTC = new Date();
    startUTC = new Date(startUTC.setDate(startUTC.getDate() - 7)).toISOString().replace('Z', ''); //default - go back 7 days
    //var endUTC = endDate;
    //commenting out to make room for dropdown calendar functionality
    //var endUTC = new Date().toISOString();
    
    const defaultRequest = {
        "deviceid": deviceId, 
        "fromUTC": startUTC, //"2022-11-02T14:00:00"
        "toUTC": endDate, //"2022-11-02T18:00:00"
        "durationInSeconds": 3600
    }

    const tagRequest = {
        "deviceid": deviceId,
    }

    

    useEffect(() => {
        // get device data
        axiosWithAuth().post(`${process.env.REACT_APP_API_URL}getdevicedata`, defaultRequest) 
        .then(response => {
            setData(response.data);

            //set isLoading: false
            setIsLoading(false);
        })
        .catch(error => {
            console.log("error: ", error);

            //set isLoading: false
            setIsLoading(false);
        })

        axiosWithAuth().post(`${process.env.REACT_APP_API_URL}getrawdata`, defaultRequest)
            .then(response => {
                setRawData(response.data);

                //set isLoading: false
                setIsLoading(false);
            })
            .catch(error => {
                console.log("error: ", error);

                //set isLoading: false
                setIsLoading(false);
            });

        axiosWithAuth().post(`${process.env.REACT_APP_API_URL}gettagdata`, tagRequest)
            .then(response => {
                setTag(response.data);

                //set isLoading: false
                setIsLoading(false);
            })
            .catch(error => {
                console.log("error: ", error);

                //set isLoading: false
                setIsLoading(false);
            })

        // get device list
        axiosWithAuth().post(`${process.env.REACT_APP_API_URL}GetUserDeviceAccess`, userData)
        .then(response => {
            if (response.data.length > 0) {
                var data = response.data.map(deviceId => {
                    if (deviceId.startsWith('A')) {
                        return [
                            
                            <Dropdown.Item href={`/DeviceData/${deviceId}`}>{deviceId}</Dropdown.Item>
                        ]
                    }
                });
                setDropdownData(data);
            }
        })
        .catch(error => {
            console.log("error: ", error);
            setIsLoading(false);
        })
    }, []);

    const updateDeviceData = (data) => {
        setData(data);
    }

    const updateDeviceTag = (tag) => {
        setTag(tag);
    }

    const updateRawData = (data) => {
        setRawData(data)
    }

    /*
    function processRawData() {
        // get raw device data
        axiosWithAuth().post(`${process.env.REACT_APP_API_URL}getrawdata`, defaultRequest)
            .then(response => {
                setRawData(response.data);

                //set isLoading: false
                setIsLoading(false);
            })
            .catch(error => {
                console.log("error: ", error);

                //set isLoading: false
                setIsLoading(false);
            });
    }
    */

    // if (data.length == 0) {
    //     return(
    //         <div className="DeviceData">
    //             <h1>No Data</h1>
    //         </div>
    //     )
    // }
    if (isLoading) {
        return (
            <Container className="d-flex align-items-center justify-content-center">      
                <Spinner animation="border"/>
            </Container>
        );
    }
    else { 
        return(      
            <>
                <Container>
                    <Row>
                        <Col md={2}>
                            <Button onClick={() => navigate(-1)}>Back</Button>
                        </Col>

                        <Col>
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    Devices
                                </Dropdown.Toggle>
                                
                                <Dropdown.Menu>
                                    {dropdownData}
                                </Dropdown.Menu>

                            </Dropdown>
                        </Col>
                    </Row>

                    <Row className="my-4 d-flex align-items-center">
                        <Col>
                            {userInfo.isOrgAdmin || userInfo.isSysAdmin ?
                                <h1>Device Name: <EditableTag value={tag} tag={tag} onChange={setTag} updateDeviceTag={updateDeviceTag} deviceid={deviceId} /> </h1> : ""}
                            {!(userInfo.isOrgAdmin || userInfo.isSysAdmin) ?
                                <h1>Device Name: {tag} </h1> : ""}
                            <h2>Serial Number: {deviceId}</h2>
                        </Col>
                        <Col>
                            <DataDurationIntervalForm 
                                deviceId={deviceId}
                                updateDeviceData={updateDeviceData}
                                updateRawData={updateRawData}
                            />
                        </Col>
                    </Row>
                    
                    <Row>
                        <Tabs defaultActiveKey="rawData">
                            <Tab eventKey="chart" title="Averaged Chart">
                                <DeviceDataChart data={data} deviceId={deviceId}/>
                            </Tab>
                            <Tab eventKey="table" title="Averaged Data">
                                <DeviceDataTable data={data}/>
                            </Tab>
                            <Tab eventKey="alerts" title="Alerts">
                                <DeviceAlertPreferences/>
                            </Tab>
                            <Tab eventKey="rawData" title="Raw Data">
                                <RawDeviceData data={rawData} deviceId={deviceId} />
                            </Tab>
                        </Tabs>
                    </Row>
                
                </Container>
            </>
        )
    }

}

export default DeviceData;